<template>
  <div class="w-full">
    <swiper v-if="isMobileDevice" ref="swiper" :options="swiperOptions">
      <swiper-slide v-if="!withoutTitle && isMobileDevice" class="!w-72">
        <slot name="title"></slot>
      </swiper-slide>

      <swiper-slide
        v-for="(slide, idx) in slides"
        :key="idx"
        class="!w-72 !h-[28rem] rounded-2xl select-none"
      >
        <slot name="slide" :slide="slide"> </slot>
      </swiper-slide>
    </swiper>
    <div
      v-else
      class="flex items-center justify-between !w-full"
      :class="{ 'flex-row-reverse': reverse }"
    >
      <div v-if="!withoutTitle">
        <slot name="title"></slot>
      </div>

      <div class="relative w-[23rem] h-[40rem] mr-20 ml-20">
        <div
          v-if="!withoutButton"
          class="absolute z-10 top-6 left-6 flex gap-3"
        >
          <a
            v-for="(slide, idx) in slides"
            :key="idx"
            class="rounded-full h-8 px-4 text-base leading-8 font-medium"
            :class="
              idx === currentSlide
                ? 'text-v-ui-text-monochrome bg-v-ui-root-monochrome-0'
                : 'text-v-ui-root-monochrome-0 bg-[#A05AFF] shadow-lg'
            "
            href="#"
            @click.prevent="goToSlide(slide, idx)"
            >{{ slide.titleShort }}</a
          >
        </div>

        <client-only v-if="!disabled">
          <swiper
            ref="swiper"
            :options="swiperOptions"
            @slideChange="onSlideChange"
          >
            <swiper-slide
              v-for="(slide, idx) in slides"
              :key="idx"
              class="rounded-2xl bg-none w-[23rem] h-[40rem] select-none"
            >
              <slot name="slide" :slide="slide"> </slot>
            </swiper-slide>
          </swiper>
        </client-only>

        <div v-else>
          <div
            v-for="(slide, idx) in slides"
            :key="idx"
            class="h-[40rem] rounded-2xl bg-none w-[23rem] select-none"
          >
            <slot name="slide" :slide="slide"> </slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';

export default {
  name: 'IndexPageSlider',
  components: { Swiper, SwiperSlide },
  props: {
    isMobileDevice: {
      type: Boolean,
      required: true
    },
    slides: {
      type: Array,
      required: true
    },
    reverse: {
      type: Boolean,
      default: false
    },
    withoutTitle: {
      type: Boolean,
      default: false
    },
    withoutButton: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      currentSlide: 0,

      swiperOptions: {
        slidesPerView: 'auto',
        spaceBetween: 24,
        slideToClickedSlide: true
      }
    };
  },
  computed: {
    disabled() {
      return !this.isMobileDevice && this.slides.length === 1;
    }
  },
  methods: {
    async goToSlide(slide, index) {
      if (slide.link) {
        await this.$router.push(slide.link);
        return;
      }

      this.$refs.swiper?.swiperInstance.slideTo(index);
    },
    onSlideChange() {
      this.currentSlide = this.$refs.swiper?.swiperInstance.realIndex;
    }
  }
};
</script>

<style lang="scss" scoped>
@import 'swiper/css/swiper.min.css';

::v-deep(.swiper-wrapper) {
  display: flex;
}
</style>
