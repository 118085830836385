<template>
  <div
    ref="slide"
    class="relative bg-v-ui-root-monochrome-0 bottom-3 xl:bg-v-ui-root-monochrome-5 rounded-2xl xl:h-[37.5rem] xl:w-[22rem] xl:bottom-0 xl:pt-20 xl:pl-6"
  >
    <p
      class="hidden text-base text-v-ui-text-monochrome-55 font-medium xl:block"
    >
      {{ $t('domains.domains.pages.main.research') }}
    </p>
    <div
      ref="scrollContainer"
      class="custom-scrollbar px-3 py-5 overflow-scroll h-[23.5rem] xl:h-[37.5rem] xl:w-[21.5rem] xl:pt-0 xl:pl-0 xl:pb-28 xl:pr-8"
    >
      <div
        v-for="(item, idx) in slideItems"
        :key="idx"
        class="relative mt-4 bg-v-ui-root-monochrome-5 py-5 px-2 flex flex-col rounded-2xl xl:mt-4 xl:bg-v-ui-root-monochrome-0 xl:p-4"
      >
        <div
          class="absolute flex items-center px-3 py-1 right-4 top-4 text-sm text-v-ui-text-monochrome font-medium rounded"
          :class="item.active ? 'bg-[#E3F6E0]' : 'bg-[#E9F2FF]'"
        >
          <span>{{ item.label }}</span>
        </div>
        <p class="text-base font-medium text-v-ui-text-monochrome pr-28">
          {{ item.title }}
        </p>
        <button
          class="flex items-center justify-center h-9 mt-8 bg-[#EFECFD] rounded w-full text-v-ui-root-main text-base font-medium"
          disabled
        >
          {{ $t('domains.domains.pages.main.research-compound') }}
        </button>
        <button
          class="flex items-center justify-center h-9 mt-4 rounded w-full text-base font-medium"
          disabled
          :class="item.resultBtnClass"
        >
          {{ item.resultBtnTitle }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AnalysisSlide',
  props: {
    slide: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      observer: null
    };
  },
  computed: {
    slideItems() {
      const items = [];
      this.slide.items.forEach((item) => {
        const label = item.active ? '' : item.label;
        const resultBtnClass = item.active
          ? 'bg-v-ui-root-main text-v-ui-root-monochrome-0'
          : 'bg-v-ui-root-monochrome-5 text-v-ui-text-monochrome-20';

        items.push({
          ...item,
          resultBtnTitle: `${this.$t(
            'domains.domains.pages.main.results'
          )} ${label}`,
          resultBtnClass
        });
      });
      return items;
    }
  },
  mounted() {
    if (window.IntersectionObserver) {
      this.observer = new IntersectionObserver((e) => {
        if (e[0].isIntersecting && this.$refs.scrollContainer) {
          this.$refs.scrollContainer.scrollTo({
            left: 0,
            top: 0,
            behavior: 'smooth'
          });
        }
      });
      this.observer.observe(this.$refs.slide);
    }
  },
  beforeDestroy() {
    this.observer?.disconnect();
    this.observer = null;
  }
};
</script>

<style lang="scss" scoped>
// TODO switch to tailwind-scrollbar library when it will be compatible with tailwind v.3

.custom-scrollbar {
  @media (min-width: 1024px) {
    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: #a05aff;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-track {
      background: #ecdeff;
      border-radius: 4px;
    }
  }
}
</style>
