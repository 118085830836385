<template>
  <div
    ref="slide"
    class="relative bg-v-ui-root-monochrome-0 bottom-3 xl:bg-v-ui-root-monochrome-5 rounded-2xl xl:h-[37.5rem] xl:w-[22rem] xl:bottom-0 xl:pt-20 xl:pl-6"
  >
    <p
      class="hidden text-base text-v-ui-text-monochrome-55 font-medium xl:block"
    >
      {{ $t('domains.domains.pages.main.today') }}
    </p>
    <div
      ref="scrollContainer"
      class="custom-scrollbar px-3 py-5 overflow-scroll h-[23.5rem] xl:h-[37.5rem] xl:w-[21.5rem] xl:pt-0 xl:pl-0 xl:pb-28 xl:pr-8"
    >
      <div
        v-for="(item, idx) in slide.items"
        :key="idx"
        class="relative mt-4 bg-v-ui-root-monochrome-5 py-5 px-2 flex flex-col rounded-2xl xl:mt-4 xl:bg-v-ui-root-monochrome-0 xl:p-4"
      >
        <div
          class="absolute flex items-center px-3 py-1 leading-7 rounded bg-v-ui-root-monochrome-0 right-4 top-4 text-sm rounded text-v-ui-text-monochrome font-bold xl:bg-v-ui-root-monochrome-5"
        >
          <span>{{ item.time }}</span>
        </div>
        <p class="text-base font-medium text-v-ui-text-monochrome">
          {{ item.specialization }}
        </p>
        <p class="text-xs text-v-ui-text-monochrome-55">
          {{ item.description }}
        </p>
        <div class="mt-5 flex items-center">
          <img
            class="rounded-full mr-3 h-10 w-10"
            :src="item.doctor.avatar"
            alt="doctor-avatar"
          />
          <p
            class="text-sm font-medium text-v-ui-root-main xl:text-base xl:leading-none"
          >
            {{ item.doctor.lastName }} <br />
            {{ item.doctor.firstName }} {{ item.doctor.middleName ?? '' }}
          </p>
        </div>
        <button
          class="flex items-center justify-center h-9 mt-6 rounded w-full text-base font-medium whitespace-nowrap"
          disabled
          :class="
            item.active
              ? 'bg-v-ui-root-main text-v-ui-root-monochrome-0'
              : 'bg-[#D0D1D3] text-v-ui-root-monochrome-0 xl:bg-v-ui-root-monochrome-5 xl:text-v-ui-text-monochrome-20'
          "
        >
          {{
            item.active
              ? $t('domains.domains.pages.main.to-consultation')
              : $t('domains.domains.pages.main.consultation-not-started')
          }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ConsultationSlide',
  props: {
    slide: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      observer: null
    };
  },
  mounted() {
    if (window.IntersectionObserver) {
      this.observer = new IntersectionObserver((e) => {
        if (e[0].isIntersecting && this.$refs.scrollContainer) {
          this.$refs.scrollContainer.scrollTo({
            left: 0,
            top: 0,
            behavior: 'smooth'
          });
        }
      });
      this.observer.observe(this.$refs.slide);
    }
  },
  beforeDestroy() {
    this.observer?.disconnect();
    this.observer = null;
  }
};
</script>

<style lang="scss" scoped>
// TODO switch to tailwind-scrollbar library when it will be compatible with tailwind v.3

.custom-scrollbar {
  @media (min-width: 1140px) {
    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: #a05aff;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-track {
      background: #ecdeff;
      border-radius: 4px;
    }
  }
}
</style>
