<template>
  <index-page-title>
    <template #title>
      {{ $t('domains.domains.pages.main.whole-clinic') }}
      <br />
      {{ $t('domains.domains.pages.main.your-phone') }}
    </template>

    <template #description>
      {{ $t('domains.domains.pages.main.with-leading-doctors') }} <br />
      {{ $t('domains.domains.pages.main.monitor-your-health') }}
    </template>

    <template #buttons>
      <nuxt-link
        class="flex items-center bg-v-ui-root-accent-alt text-base font-medium text-v-ui-root-monochrome-0 h-9 px-4 rounded-full whitespace-nowrap xl:h-12 xl:text-xl"
        :to="{ name: 'dashboard' }"
      >
        {{ $t('domains.domains.pages.main.select-specialist') }}
      </nuxt-link>
      <a
        class="xl:hidden mt-4 flex items-center text-base font-medium text-[#5B3AEF] h-9 px-4 rounded-full border-2 border-[#5B3AEF] whitespace-nowrap xl:h-12 xl:mt-0 xl:ml-4 xl:text-xl"
        href="https://doctis.app"
      >
        {{ $t('domains.domains.pages.main.download-application') }}
      </a>
    </template>
  </index-page-title>
</template>

<script>
import IndexPageTitle from '../index-page-title/IndexPageTitle';
export default {
  name: 'IndexTitle',
  components: { IndexPageTitle }
};
</script>

<style scoped></style>
