<template>
  <div class="flex flex-col">
    <h1 class="text-3xl font-bold xl:text-5xl">
      <slot name="title"></slot>
    </h1>
    <p
      class="mt-3 text-v-ui-text-monochrome-55 font-medium text-base xl:text-xl"
    >
      <slot name="description"></slot>
    </p>
    <div
      class="flex flex-col items-start mt-5 xl:flex-row xl:flex-wrap xl:gap-3"
    >
      <slot name="buttons"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'IndexPageTitle'
};
</script>
